import React from 'react'
import Slider from "react-slick"
import Blog0 from "../../images/top10.jpg"
import Blog1 from "../../images/others/buyhouse.jpg"
import Blog2 from "../../images/others/newhome.webp"
import Blog3 from "../../images/others/bayareakids.jpg"
import Blog4 from "../../images/others/privateschool.jpg"

const LatestNewsCarousel = () => {
    let settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    }
    return (
        <section
        className="pad-t80 pad-b50"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
            <Slider {...settings} className="row">
              <div className="col-md-12">
                <div className="latest-news">
                  <div className="latest-news-img">
                    <img src={Blog1} alt="blog-post" />
                    <div>
                      <span>Hot</span>
                    </div>
                  </div>
                  <h4>
                    <a href="/how-to-buy-house-in-bay-area">
                      湾区首次买房全攻略
                    </a>
                  </h4>
                  <p>
                  对于湾区工作生活的小伙伴们来讲，首次在湾区购房可能略微有点无从下手，今天Jing就来跟大家聊聊，首次购房你不得不知道的那点事儿~~
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="latest-news">
                  <div className="latest-news-img">
                    <img src={Blog2} alt="blog-post" />
                    <div>
                      <span>Hot</span>
                    </div>
                  </div>
                  <h4>
                    <a href="/bay-area-new-home">
                      湾区买新房必读 9件该知道的事
                    </a>
                  </h4>
                  <p>
                  越来越多的年轻买家们选择购买新房，今天，Jing就来跟大家一起聊聊“买新房之道”。
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="latest-news">
                  <div className="latest-news-img">
                    <img src={Blog3} alt="blog-post" />
                    <div>
                      <span>Hot</span>
                    </div>
                  </div>
                  <h4>
                    <a href="/bay-area-top-city">
                    湾区最适合家庭居住的10个城市
                    </a>
                  </h4>
                  <p>
                  挑选最适合养家生活的城市真的非常重要。湾区最适合家庭居住的城市，参考因素包含当地的学校选择、安全与治安、经济负担、附近是否有便利的家庭设施等，非常值得还在挑选居住城市的人参考。
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="latest-news">
                  <div className="latest-news-img">
                    <img src={Blog4} alt="blog-post" />
                    <div>
                      <span>Hot</span>
                    </div>
                  </div>
                  <h4>
                    <a href="/bay-area-private-school">
                    新出炉的湾区TOP 10私校榜单！
                    </a>
                  </h4>
                  <p>
                  在这个内卷的时代，即使家中的娃上着最好的公校，很多家长朋友们也忍不住留意着周边的私校，那么今天跟大家一起扒一扒我们大湾区的顶级私校有哪些。
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="latest-news">
                  <div className="latest-news-img">
                    <img src={Blog0} alt="blog-post" />
                    <div>
                      <span>Hot</span>
                    </div>
                  </div>
                  <h4>
                    <a href="/2021">
                    回顾2021年我们帮客户成交的房子们
                    </a>
                  </h4>
                  <p>
                  对于湾区工作生活的小伙伴们来讲，首次在湾区购房可能略微有点无从下手，今天Jing就来跟大家聊聊，首次购房你不得不知道的那点事儿~~
                  </p>
                </div>
              </div>
            </Slider>
            
            
        </div>
      </section>
    )
}

export default LatestNewsCarousel
