import React from "react"
import HeaderStyle1 from "../components/Header/HeaderStyle1"
import SectionTitle from "../components/SectionTitle/SectionTitle"
import Jing from "../images/jing.jpg"
import FeatureStyle2 from "../components/Features/FeatureStyle2"
import TransactionGrid from "../components/Portfolio/TransactionGrid"
import Testimonial from "../components/Testimonial"
import FooterStyle1 from "../components/Footer/FooterStyle1"

import HallOfFame from "../images/hall_of_fame.svg"
import Executive from "../images/executive.svg"
import Chairmans from "../images/chairmans.svg"
import Img1 from "../images/webinar.jpg"
import transaction_list from "../components/transaction_list"
import ReviewTable from '../components/ReviewTable'
import LatestNewsCarousel from '../components/LatestNews/LatestNewsCarousel'
import lang from "../components/lang"
import { Link } from "gatsby"

import {Sparklines, SparklinesLine, SparklinesText} from 'react-sparklines';

const Homepage1 = (props) => {
  const {pathContext} = props;
  const cities_data = pathContext.city;

  const elements = []
  for (const city_data of cities_data) {
    const prices = [];
    for (const staticsItem of city_data.statics) {
      if (staticsItem.median_sales_price !== null) {
        prices.push(staticsItem.median_sales_price);
      } else {
      }
    }
    if (prices.length === 0) {
      continue;
    }
    elements.push(
      <div className="col-md-3" key={city_data.name}>
        <Link to={"/" + city_data.name}>
          <SparklinesText text={city_data.name}/>
          <Sparklines data={prices.slice(-12)}>
            <SparklinesLine />
          </Sparklines>
        </Link>
      </div>
    )
  }

  return (
    <>
      <HeaderStyle1 />

      {/* Start Why Choose Us Section Section*/}
      <section className="pad-t60">
        <div className="container">
          <div className="row">
            <h3
              style={{
                fontSize: "30px",
                lineHeight: "40px",
                margin: "0 0 10px",
                textTransform: "uppercase",
              }}
            >
              {lang === "EN" ? "WHY CHOOSE JING": "为什么选择JING XUE"}
            </h3>
          </div>
          <div className="row">
            <div className="col-md-7">
              {/*
              <p>
                Always committed to providing a high level of service, Jing is known for timely and concise communication, always giving her best to exceed her clients' expectations. As a good listener, Jing will be responsive to all your needs, and guide new and experienced home buyers and sellers through every step of the process, and navigates complex transactions with ease and expertise to deliver results.
              </p>
              */}
              <div className="feature-list" style={{ marginTop: "40px" }}>
                <div className="row">
                  <div className="col-md-6">
                    <FeatureStyle2
                      icon="icon-trophy"
                      title="湾区Top房产经纪"
                      text="成交量高,Remax经纪人中个人排名#1,熟悉南湾,东湾,三谷房产市场"
                    />
                  </div>
                  <div className="col-md-6">
                    <FeatureStyle2
                      icon="icon-documents"
                      title="专业解读房检报告"
                      text="为客户综合分析房产优缺点,包括房屋质量,高压电线,交通噪音等因素"
                    />
                  </div>
                  <div className="col-md-6">
                    <FeatureStyle2
                      icon="icon-hotairballoon"
                      title="专业 Zillow 全5星好评"
                      text="记录每一位客户需求，湾区最受好评的房地产经纪人之一"
                    />
                  </div>
                  <div className="col-md-6">
                    <FeatureStyle2
                      icon="icon-ribbon"
                      title="一切为了客户利益"
                      text="全面考虑客户的利益，真正把客户的事当做自己的事来做"
                    />
                  </div>
                </div>
              </div>
              <div className="template-image mbl-margin-bottom">
                <img className="img-fluid" src={Img1} alt=""/>
              </div>
            </div>
            <div className="col-md-5">
              <div className="template-image text-center">
                <img src={Jing} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Why Choose Us Section Section*/}

      <LatestNewsCarousel />

      {/* Start Pricing Table Section*/}
      <section className="pad-t60">
          <div className="container">
          <ReviewTable />
          </div>
      </section>
      {/* End Pricing Table Section*/}

      {/* Start Feature Section */}
      <section
        className="pad-t80 pad-b30 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="湾区各城市房价走势" />
            </div>
          </div>
          <div className="row">
            {elements}
          </div>
        </div>
      </section>
      {/* End Feature Section */}

      {/* Start Portfolio Section*/}
      <section className="pad-t60 pad-b50">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={lang === "EN" ? "Jing's Recent Transaction": "近期交易"} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TransactionGrid transactions={transaction_list}/>
            </div>
          </div>
        </div>
      </section>
      {/* End Portfolio Section*/}

      {/* Start Testimonial Section*/}
      <section className="pad60 parallax" style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="What People Say" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mbl-margin-bottom">
              <ul className="logo-group width33">
                <li className="client-logo">
                  <a href="/">
                    <img src={HallOfFame} alt="Client's Logo" />
                    <div className="designation">2018</div>
                  </a>
                </li>
                <li className="client-logo">
                  <a href="/">
                    <img src={Executive} alt="Client's Logo" />
                    <div className="designation">2019</div>
                  </a>
                </li>
                <li className="client-logo">
                  <a href="/">
                    <img src={Chairmans} alt="Client's Logo" />
                    <div className="designation">2019</div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <Testimonial/>
            </div>
          </div>
        </div>
      </section>
      {/* End Testimonial Section*/}

      {/* Start Footer Section*/}
      <FooterStyle1 />
      {/* End Footer Section*/}
    </>
  )
}

export default Homepage1
