import React from "react"
import Slider from "react-slick"
import CommentImg2 from "../images/reviews/self-image.jpg"
import CommentImg3 from "../images/reviews/default-user.png"

const Testimonial = ({textStyle}) => {
  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Slider {...settings} className="testimonial-carousel">
      <div className={`testimonial-item text-center ${textStyle}`}>
        <p>
        Being quite new to the US house market, we were looking for an agent with loads of local market experience. Jing has been amazing to deal with. She is very knowledgeable, gave us really useful advice along the way, kept us informed, and at a crucial time of negotiations, she was able to secure an  amazing deal. What also helps is that Jing is a very well know and respected agent, so the seller's agent was willing to work out the deal with us, even though other similar offers were on the table. I can really recommend anyone looking for a house to use Jing as an agent, you will not regret it.
        </p>
        <img src={CommentImg3} alt="" className="img-circle" />
        <div className="name">John Lin</div>
        <div className="designation">Pleasanton Buyer</div>
      </div>
      <div className={`testimonial-item text-center ${textStyle}`}>
        <p>
        We really enjoyed working with Jing, she did a great job and we got the house off-market without any competition. The whole transaction went very smoothly beyond our expectations.
As a real estate agent, she is very sharp, detail-oriented, responsible, and resourceful. She went through all the  disclosures, reports and comps with us and patiently answered all the questions we have. She is also a genuine person who tells the truth and looks out for you, as a friend and client. We highly recommend Jing to our friends and relatives.
We look forward to working with her again in the future.
        </p>
        <img src={CommentImg2} alt="" className="img-circle" />
        <div className="name">Amy Zhange</div>
        <div className="designation">San Jose Seller</div>
      </div>
    </Slider>
  )
}

export default Testimonial
