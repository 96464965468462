import React, { useState } from "react"
import Ads from "../../images/ads.jpg"

const Element = ({ imgSource, title, price, tag, url, date }) => (
  <div className="col-md-4 col-sm-6">
    <div className="portfolio-post-2 mb30">
      <img src={imgSource} alt={title}/>
      <div className="portfolio-details">
        <h4>{title} {price}</h4>
        <p>{tag} || {date}</p>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <i className="icon-attachment"></i>
        </a>
      </div>
    </div>
  </div>
)

const TransactionGrid = ({ transactions }) => {
  const [display, setDisplay] = useState(transactions)

  const handleDisplay = category => {
    const categoryToDisplay = transactions.filter(item => item.category === category)
    setDisplay(categoryToDisplay)
  }

  return (
    <>
      <ul className="filter">
        <li>
          <button onClick={() => setDisplay(transactions)}>Show All</button>
        </li>
        <li>
          <button onClick={() => handleDisplay("Single Family")}>Single Family</button>
        </li>
      </ul>
      <div className="row portfolio-box">
        {display.map(element => (
          <Element key={element.id} {...element} />
        ))}
          <div className="col-md-4 col-sm-6">
            <div className="portfolio-post-2 mb30">
              <img src={Ads} alt=""/>
              <div className="portfolio-details">
                <h4>Jing Xue</h4>
                <p>湾区Top房产经纪</p>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <i className="icon-attachment"></i>
                </a>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default TransactionGrid
